import axios from "axios";
import { actionTypes } from "./action.types";

// Events
// Get all the events
export const Getevents = () => async (dispatch) => {
    dispatch({ type: actionTypes.GET_EVENT_LOADING });
    try {
        let res = await axios.get(
            `https://api.scitechpark.org.in/events/getallevent`
        );
        if (res.data) {
            dispatch({
                type: actionTypes.GET_EVENT_SUCCESS,
                payload: res.data.result,
            });
        }
        console.log("Getevents action", res.data);
        if (res) {
            return res;
        }
        return;
    } catch (error) {
        dispatch({ type: actionTypes.GET_EVENT_ERROR });
        throw error;
    }
};

// Get event by id
export const GetEventById = (Event_id) => async (dispatch) => {
    dispatch({ type: actionTypes.GET_EVENT_BY_ID_LOADING });
    try {
        let res = await axios.post(
            `https://api.scitechpark.org.in/events/getevent`,
            { Event_id: Event_id }
        );
        if (res) {
            dispatch({
                type: actionTypes.GET_EVENT_BY_ID_SUCCESS,
                payload: res.data.result,
            });
        }
        console.log("GetEventsById", res.data);
        return res.data.data;
    } catch (error) {
        dispatch({ type: actionTypes.GET_EVENT_BY_ID_ERROR });
    }
};

// Add event
export const AddEvent =
    (formData, handleResponseAddEvent) => async (dispatch) => {
        dispatch({ type: actionTypes.ADD_EVENT_LOADING });
        try {
            let res = await axios.post(
                "https://api.scitechpark.org.in/events/addevent",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            if (res.data) {
                dispatch({
                    type: actionTypes.ADD_EVENT_SUCCESS,
                    payload: res.data.result,
                });
            }
            handleResponseAddEvent(res.data.success, res);

            console.log("AddEvent action", res.data.result);
            return res;
        } catch (error) {
            dispatch({ type: actionTypes.ADD_EVENT_ERROR });
            handleResponseAddEvent(false);
            throw error;
        }
    };

// Update the event
export const UpdateEvent =
    (id, changes, handleResponseUpdateEvent) => async (dispatch) => {
        dispatch({ type: actionTypes.UPDATE_EVENT_LOADING });
        try {
            const res = await axios.put(
                `https://api.scitechpark.org.in/events/${id}`,
                changes
            );
            dispatch({
                type: actionTypes.UPDATE_EVENT_SUCCESS,
                payload: res.data.result,
            });
            if (res.data) {
                handleResponseUpdateEvent(res.data.success, res);
            }
            console.log("UpdateEvent", res.data);
        } catch (error) {
            dispatch({ type: actionTypes.UPDATE_EVENT_ERROR });
            handleResponseUpdateEvent(false);
            throw error;
        }
    };

// Delete the event
export const DeleteEvent = (Event_id) => async (dispatch) => {
    dispatch({ type: actionTypes.DELETE_EVENT_LOADING });
    try {
        let res = await axios.post(
            `https://api.scitechpark.org.in/events/deleteevent`,
            { Event_id: Event_id }
        );
        if (res) {
            dispatch({
                type: actionTypes.DELETE_EVENT_SUCCESS,
                payload: res.data.result,
            });
        }
        console.log("GetEventsById", res.data);
        return res.data.data;
    } catch (error) {
        dispatch({ type: actionTypes.DELETE_EVENT_ERROR });
    }
};

// GROWTH LAB
// Get all the growth lab
export const GetGrowthLab = () => async (dispatch) => {
    dispatch({ type: actionTypes.GET_GROWTH_LAB_LOADING });
    try {
        let res = await axios.post(`https://api.scitechpark.org.in/growth/getall`);
        if (res.data) {
            dispatch({
                type: actionTypes.GET_GROWTH_LAB_SUCCESS,
                payload: res.data.result,
            });
        }
        console.log("GetGrowthLab", res.data);
        if (res) {
            return res;
        }
        return;
    } catch (error) {
        dispatch({ type: actionTypes.GET_GROWTH_LAB_ERROR });
        throw error;
    }
};

// Get growth lab by id
export const GetGrowthLabById = (Id) => async (dispatch) => {
    dispatch({ type: actionTypes.GET_GROWTH_LAB_BY_ID_LOADING });
    try {
        let res = await axios.post(`https://api.scitechpark.org.in/growth/get`, {
            Id: Id,
        });
        if (res) {
            dispatch({
                type: actionTypes.GET_GROWTH_LAB_BY_ID_SUCCESS,
                payload: res.data.result,
            });
        }
        console.log("GetGrowthLabById", res.data);
        return res.data.data;
    } catch (error) {
        dispatch({ type: actionTypes.GET_GROWTH_LAB_BY_ID_ERROR });
    }
};

// Add Growth lab
export const AddGrowthLab =
    (payload, handleResponseAddGrowthLab) => async (dispatch) => {
        dispatch({ type: actionTypes.ADD_GROWTH_LAB_LOADING });
        try {
            let res = await axios.post(
                "https://api.scitechpark.org.in/growth/add",
                payload
            );
            if (res.data) {
                dispatch({
                    type: actionTypes.ADD_GROWTH_LAB_SUCCESS,
                    payload: res.data.result,
                });
            }
            handleResponseAddGrowthLab(res.data.success, res);

            console.log("AddEvent action", res.data.result);
            return res;
        } catch (error) {
            dispatch({ type: actionTypes.ADD_GROWTH_LAB_ERROR });
            handleResponseAddGrowthLab(false);
            throw error;
        }
    };

// Delete the GrowthLab
export const DeleteGrowthLab = (Id) => async (dispatch) => {
    dispatch({ type: actionTypes.DELETE_GROWTH_LAB_LOADING });
    try {
        let res = await axios.post(`https://api.scitechpark.org.in/growth/delete`, {
            Id: Id,
        });
        if (res) {
            dispatch({
                type: actionTypes.DELETE_GROWTH_LAB_SUCCESS,
                payload: res.data.result,
            });
        }
        console.log("DeleteGrowthLab", res.data);
        return res.data.data;
    } catch (error) {
        dispatch({ type: actionTypes.DELETE_GROWTH_LAB_ERROR });
    }
};

// Update the event
export const UpdateGrowthLab =
    (id, changes, handleResponseUpdateGrowthLab) => async (dispatch) => {
        dispatch({ type: actionTypes.UPDATE_GROWTH_LAB_LOADING });
        try {
            const res = await axios.put(
                `https://api.scitechpark.org.in/growth/${id}`,
                changes
            );
            dispatch({
                type: actionTypes.UPDATE_GROWTH_LAB_SUCCESS,
                payload: res.data.result,
            });
            if (res.data) {
                handleResponseUpdateGrowthLab(res.data.success, res);
            }
            console.log("UpdateGrowthLab", res.data);
        } catch (error) {
            dispatch({ type: actionTypes.UPDATE_GROWTH_LAB_ERROR });
            handleResponseUpdateGrowthLab(false);
            throw error;
        }
    };

// Banners
// Get all the banners
export const GetBanners = () => async (dispatch) => {
    dispatch({ type: actionTypes.GET_BANNER_LOADING });
    try {
        let res = await axios.get(`https://api.scitechpark.org.in/banner/getall`);
        if (res.data) {
            dispatch({
                type: actionTypes.GET_BANNER_SUCCESS,
                payload: res.data.result,
            });
        }
        console.log("GetBanners action", res.data);
        if (res) {
            return res;
        }
        return;
    } catch (error) {
        dispatch({ type: actionTypes.GET_BANNER_ERROR });
        throw error;
    }
};

// Get event by id
export const GetBannerById = (Banner_id) => async (dispatch) => {
    dispatch({ type: actionTypes.GET_BANNER_BY_ID_LOADING });
    try {
        let res = await axios.post(`https://api.scitechpark.org.in/banner/get`, {
            Banner_id: Banner_id,
        });
        if (res) {
            dispatch({
                type: actionTypes.GET_BANNER_BY_ID_SUCCESS,
                payload: res.data.result,
            });
        }
        console.log("GetEventsById", res.data);
        return res.data.data;
    } catch (error) {
        dispatch({ type: actionTypes.GET_BANNER_BY_ID_ERROR });
    }
};

// Add event
export const AddBanner =
    (formData, handleResponseAddBanner) => async (dispatch) => {
        dispatch({ type: actionTypes.ADD_BANNER_LOADING });
        try {
            let res = await axios.post(
                "https://api.scitechpark.org.in/banner/add",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            if (res.data) {
                dispatch({
                    type: actionTypes.ADD_BANNER_SUCCESS,
                    payload: res.data.result,
                });
            }
            handleResponseAddBanner(res.data.success, res);

            console.log("AddEvent action", res.data.result);
            return res;
        } catch (error) {
            dispatch({ type: actionTypes.ADD_BANNER_ERROR });
            handleResponseAddBanner(false);
            throw error;
        }
    };

// Update the event
export const UpdateBanner =
    (Banner_id, changes, handleResponseUpdateBanner) => async (dispatch) => {
        dispatch({ type: actionTypes.UPDATE_BANNER_LOADING });
        try {
            const res = await axios.put(
                `https://api.scitechpark.org.in/banner/${Banner_id}`,
                changes
            );
            dispatch({
                type: actionTypes.UPDATE_BANNER_SUCCESS,
                payload: res.data.result,
            });
            if (res.data) {
                handleResponseUpdateBanner(res.data.success, res);
            }
            console.log("UpdateBanner", res.data);
        } catch (error) {
            dispatch({ type: actionTypes.UPDATE_BANNER_ERROR });
            handleResponseUpdateBanner(false);
            throw error;
        }
    };

// Delete the event
export const DeleteBanner = (Banner_id) => async (dispatch) => {
    dispatch({ type: actionTypes.DELETE_BANNER_LOADING });
    try {
        let res = await axios.post(`https://api.scitechpark.org.in/banner/delete`, {
            Banner_id: Banner_id,
        });
        if (res) {
            dispatch({
                type: actionTypes.DELETE_BANNER_SUCCESS,
                payload: res.data.result,
            });
        }
        console.log("DeleteBanner", res.data);
        return res.data.data;
    } catch (error) {
        dispatch({ type: actionTypes.DELETE_BANNER_ERROR });
    }
};

// News
// Get all the News
export const GetNews = () => async (dispatch) => {
    dispatch({ type: actionTypes.GET_NEWS_LOADING });
    try {
        let res = await axios.get(`https://api.scitechpark.org.in/news/getall`);
        if (res.data) {
            dispatch({
                type: actionTypes.GET_NEWS_SUCCESS,
                payload: res.data.result,
            });
        }
        console.log("GetNews action", res.data);
        if (res) {
            return res;
        }
        return;
    } catch (error) {
        dispatch({ type: actionTypes.GET_NEWS_ERROR });
        throw error;
    }
};

// Get News by id
export const GetNewsById = (News_id) => async (dispatch) => {
    dispatch({ type: actionTypes.GET_NEWS_BY_ID_LOADING });
    try {
        let res = await axios.post(`https://api.scitechpark.org.in/news/get`, {
            News_id: News_id,
        });
        if (res) {
            dispatch({
                type: actionTypes.GET_NEWS_BY_ID_SUCCESS,
                payload: res.data.result,
            });
        }
        console.log("GetNewsById", res.data);
        return res.data.data;
    } catch (error) {
        dispatch({ type: actionTypes.GET_NEWS_BY_ID_ERROR });
    }
};

// Add News
export const AddNews =
    (formData, handleResponseAddNews) => async (dispatch) => {
        dispatch({ type: actionTypes.ADD_NEWS_LOADING });
        try {
            let res = await axios.post(
                "https://api.scitechpark.org.in/news/add",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Content-Disposition": "inline",
                    },
                }
            );
            if (res.data) {
                dispatch({
                    type: actionTypes.ADD_NEWS_SUCCESS,
                    payload: res.data.result,
                });
            }
            handleResponseAddNews(res.data.success, res);

            console.log("AddNews action", res.data.result);
            return res;
        } catch (error) {
            dispatch({ type: actionTypes.ADD_NEWS_ERROR });
            handleResponseAddNews(false);
            throw error;
        }
    };

// Update the News
export const UpdateNews =
    (id, changes, handleResponseUpdateNews) => async (dispatch) => {
        dispatch({ type: actionTypes.UPDATE_EVENT_LOADING });
        try {
            const res = await axios.put(
                `https://api.scitechpark.org.in/news/${id}`,
                changes
            );
            dispatch({
                type: actionTypes.UPDATE_EVENT_LOADING,
                payload: res.data.result,
            });
            if (res.data) {
                handleResponseUpdateNews(res.data.success, res);
            }
            console.log("UpdateNews", res.data);
        } catch (error) {
            dispatch({ type: actionTypes.UPDATE_EVENT_ERROR });
            handleResponseUpdateNews(false);
            throw error;
        }
    };

// Delete the News
export const DeleteNews = (News_id) => async (dispatch) => {
    dispatch({ type: actionTypes.DELETE_NEWS_SUCCESS });
    try {
        let res = await axios.post(`https://api.scitechpark.org.in/news/delete`, {
            News_id: News_id,
        });
        if (res) {
            dispatch({
                type: actionTypes.DELETE_NEWS_SUCCESS,
                payload: res.data.result,
            });
        }
        console.log("DeleteNews", res.data);
        return res.data.data;
    } catch (error) {
        dispatch({ type: actionTypes.DELETE_NEWS_ERROR });
    }
};

// Associations
// Get all the Associations
export const GetAssociations = () => async (dispatch) => {
    dispatch({ type: actionTypes.GET_ASSOCIATION_LOADING });
    try {
        let res = await axios.get(
            `https://api.scitechpark.org.in/association/getall`
        );
        if (res.data) {
            dispatch({
                type: actionTypes.GET_ASSOCIATION_SUCCESS,
                payload: res.data.result,
            });
        }
        console.log("GetNews action", res.data);
        if (res) {
            return res;
        }
        return;
    } catch (error) {
        dispatch({ type: actionTypes.GET_ASSOCIATION_ERROR });
        throw error;
    }
};

// Get association by id
export const GetAssociationById = (Association_id) => async (dispatch) => {
    dispatch({ type: actionTypes.GET_ASSOCIATION_BY_ID_LOADING });
    try {
        let res = await axios.post(
            `https://api.scitechpark.org.in/association/get`,
            { Association_id: Association_id }
        );
        if (res) {
            dispatch({
                type: actionTypes.GET_ASSOCIATION_BY_ID_SUCCESS,
                payload: res.data.result,
            });
        }
        console.log("GetAssociationById", res.data);
        return res.data.data;
    } catch (error) {
        dispatch({ type: actionTypes.GET_ASSOCIATION_BY_ID_ERROR });
    }
};

// Add association
export const AddAssociation =
    (formData, handleResponseAddAssociation) => async (dispatch) => {
        dispatch({ type: actionTypes.ADD_ASSOCIATION_LOADING });
        try {
            let res = await axios.post(
                "https://api.scitechpark.org.in/association/add",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            if (res.data) {
                dispatch({
                    type: actionTypes.ADD_ASSOCIATION_SUCCESS,
                    payload: res.data.result,
                });
            }
            handleResponseAddAssociation(res.data.success, res);

            console.log("AddAssociation action", res.data.result);
            return res;
        } catch (error) {
            dispatch({ type: actionTypes.ADD_ASSOCIATION_ERROR });
            handleResponseAddAssociation(false);
            throw error;
        }
    };

// Update the association
export const UpdateAssociation =
    (id, changes, handleResponseUpdateAssociation) => async (dispatch) => {
        dispatch({ type: actionTypes.UPDATE_ASSOCIATION_LOADING });
        try {
            const res = await axios.put(
                `https://api.scitechpark.org.in/association/${id}`,
                changes
            );
            dispatch({
                type: actionTypes.UPDATE_ASSOCIATION_SUCCESS,
                payload: res.data.result,
            });
            if (res.data) {
                handleResponseUpdateAssociation(res.data.success, res);
            }
            console.log("UpdateNews", res.data);
        } catch (error) {
            dispatch({ type: actionTypes.UPDATE_ASSOCIATION_ERROR });
            handleResponseUpdateAssociation(false);
            throw error;
        }
    };

// Delete the News
export const DeleteAssociation = (Association_id) => async (dispatch) => {
    dispatch({ type: actionTypes.DELETE_ASSOCIATION_LOADING });
    try {
        let res = await axios.post(
            `https://api.scitechpark.org.in/association/delete`,
            { Association_id: Association_id }
        );
        if (res) {
            dispatch({
                type: actionTypes.DELETE_ASSOCIATION_SUCCESS,
                payload: res.data.result,
            });
        }
        console.log("DeleteAssociation", res.data);
        return res.data.data;
    } catch (error) {
        dispatch({ type: actionTypes.DELETE_ASSOCIATION_ERROR });
    }
};

// contact
// Get all the Contacts
export const GetContacts = () => async (dispatch) => {
    dispatch({ type: actionTypes.GET_CONTACT_LOADING });
    try {
        let res = await axios.get(
            `https://api.scitechpark.org.in/contact_us/getall`
        );
        if (res.data) {
            dispatch({
                type: actionTypes.GET_CONTACT_SUCCESS,
                payload: res.data.result,
            });
        }
        console.log("GetContacts action", res.data);
        if (res) {
            return res;
        }
        return;
    } catch (error) {
        dispatch({ type: actionTypes.GET_CONTACT_ERROR });
        throw error;
    }
};

// Get association by id
export const GetContactById = (Id) => async (dispatch) => {
    dispatch({ type: actionTypes.GET_CONTACT_BY_ID_LOADING });
    try {
        let res = await axios.post(
            `https://api.scitechpark.org.in/contact_us/get`,
            { Id: Id }
        );
        if (res) {
            dispatch({
                type: actionTypes.GET_CONTACT_BY_ID_SUCCESS,
                payload: res.data.result,
            });
        }
        console.log("GetContactsById", res.data);
        return res.data.data;
    } catch (error) {
        dispatch({ type: actionTypes.GET_CONTACT_BY_ID_ERROR });
    }
};

// Awards
// Get all the awards
export const GetAwards = () => async (dispatch) => {
    dispatch({ type: actionTypes.GET_AWARD_LOADING });
    try {
        let res = await axios.get(`https://api.scitechpark.org.in/awards/getall`);
        if (res.data) {
            dispatch({
                type: actionTypes.GET_AWARD_SUCCESS,
                payload: res.data.result,
            });
        }
        console.log("GetAwards action", res.data);
        if (res) {
            return res;
        }
        return;
    } catch (error) {
        dispatch({ type: actionTypes.GET_AWARD_ERROR });
        throw error;
    }
};

// Get awards by id
export const GetAwardById = (Award_id) => async (dispatch) => {
    dispatch({ type: actionTypes.GET_AWARD_BY_ID_LOADING });
    try {
        let res = await axios.post(`https://api.scitechpark.org.in/awards/get`, {
            Award_id: Award_id,
        });
        if (res) {
            dispatch({
                type: actionTypes.GET_AWARD_BY_ID_SUCCESS,
                payload: res.data.result,
            });
        }
        console.log("GetAwardById", res.data);
        return res.data.data;
    } catch (error) {
        dispatch({ type: actionTypes.GET_AWARD_BY_ID_ERROR });
    }
};

// Add awards
export const AddAwards =
    (formData, handleResponseAddAwards) => async (dispatch) => {
        dispatch({ type: actionTypes.ADD_AWARD_LOADING });
        try {
            let res = await axios.post(
                "https://api.scitechpark.org.in/awards/add",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            if (res.data) {
                dispatch({
                    type: actionTypes.ADD_AWARD_SUCCESS,
                    payload: res.data.result,
                });
            }
            handleResponseAddAwards(res.data.success, res);

            console.log("AddAwards action", res.data.result);
            return res;
        } catch (error) {
            dispatch({ type: actionTypes.ADD_AWARD_ERROR });
            handleResponseAddAwards(false);
            throw error;
        }
    };

// Update the awards
export const UpdateAwards =
    (id, changes, handleResponseUpdateAwards) => async (dispatch) => {
        dispatch({ type: actionTypes.UPDATE_AWARD_LOADING });
        try {
            const res = await axios.put(
                `https://api.scitechpark.org.in/awards/${id}`,
                changes
            );
            dispatch({
                type: actionTypes.UPDATE_AWARD_SUCCESS,
                payload: res.data.result,
            });
            if (res.data) {
                handleResponseUpdateAwards(res.data.success, res);
            }
            console.log("UpdateAwards", res.data);
        } catch (error) {
            dispatch({ type: actionTypes.UPDATE_AWARD_ERROR });
            handleResponseUpdateAwards(false);
            throw error;
        }
    };



// Announcements
// Get all announcements
export const GetAnnouncements = () => async (dispatch) => {
    dispatch({ type: actionTypes.GET_ANNOUNCEMENT_LOADING });
    try {
        let res = await axios.get(`https://api.scitechpark.org.in/announcement/getall`);
        if (res.data) {
            dispatch({
                type: actionTypes.GET_ANNOUNCEMENT_SUCCESS,
                payload: res.data.result,
            });
        }
        // console.log("GetAnnouncements action", res.data);
        if (res) {
            return res;
        }
        return;
    } catch (error) {
        dispatch({ type: actionTypes.GET_ANNOUNCEMENT_ERROR });
        throw error;
    }
};

// Get announcement by ID
export const GetAnnouncementById = (Announcement_id) => async (dispatch) => {
    dispatch({ type: actionTypes.GET_ANNOUNCEMENT_BY_ID_LOADING });
    try {
        let res = await axios.post(`https://api.scitechpark.org.in/announcement/get`, {
            Announcement_id: Announcement_id,
        });
        if (res) {
            dispatch({
                type: actionTypes.GET_ANNOUNCEMENT_BY_ID_SUCCESS,
                payload: res.data.result,
            });
        }
        console.log("GetAnnouncementById", res.data);
        return res.data.result;
    } catch (error) {
        dispatch({ type: actionTypes.GET_ANNOUNCEMENT_BY_ID_ERROR });
    }
};

// Add announcement
export const AddAnnouncement =
    (formData, handleResponseAddAnnouncement) => async (dispatch) => {
        dispatch({ type: actionTypes.ADD_ANNOUNCEMENT_LOADING });
        try {
            let res = await axios.post(
                "https://api.scitechpark.org.in/announcement/add",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            if (res.data) {
                dispatch({
                    type: actionTypes.ADD_ANNOUNCEMENT_SUCCESS,
                    payload: res.data.result,
                });
            }
            handleResponseAddAnnouncement(res.data.success, res);

            console.log("AddAnnouncement action", res.data.result);
            return res;
        } catch (error) {
            dispatch({ type: actionTypes.ADD_ANNOUNCEMENT_ERROR });
            handleResponseAddAnnouncement(false);
            throw error;
        }
    };

// Update the announcement
export const UpdateAnnouncement =
    (id, changes, handleResponseUpdateAnnouncement) => async (dispatch) => {
        dispatch({ type: actionTypes.UPDATE_ANNOUNCEMENT_LOADING });
        try {
            const res = await axios.put(
                `https://api.scitechpark.org.in/announcement/${id}`,
                changes
            );
            dispatch({
                type: actionTypes.UPDATE_ANNOUNCEMENT_SUCCESS,
                payload: res.data.result,
            });
            if (res.data) {
                handleResponseUpdateAnnouncement(res.data.success, res);
            }
            console.log("UpdateAnnouncement", res.data);
        } catch (error) {
            dispatch({ type: actionTypes.UPDATE_ANNOUNCEMENT_ERROR });
            handleResponseUpdateAnnouncement(false);
            throw error;
        }
    };

// Delete the announcement
export const DeleteAnnouncement = (id) => async (dispatch) => {
    dispatch({ type: actionTypes.DELETE_ANNOUNCEMENT_LOADING });
    try {
        const res = await axios.delete(`https://api.scitechpark.org.in/announcement/${id}`);
        dispatch({
            type: actionTypes.DELETE_ANNOUNCEMENT_SUCCESS,
            payload: id,
        });
        console.log("DeleteAnnouncement", res.data);
    } catch (error) {
        dispatch({ type: actionTypes.DELETE_ANNOUNCEMENT_ERROR });
        throw error;
    }
};
