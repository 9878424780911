import * as React from "react";
import {
    Grid,
    ImageList,
    ImageListItem,
    TextField,
} from "@mui/material";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useState, useEffect } from "react";
import { AddBanner, GetBannerById, UpdateBanner } from "../../../Redux/action";

const widthStyle = {
    width: "45%",
    "@media (max-width: 600px)": {
        width: "100%",
    },
};

const BannerForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();
    const type = location?.state?.type;
    const idFromLocation = location?.state?.id;
    const { bannerById } = useSelector((store) => store.BannerReducer);
    const [imagePreview, setImagePreview] = useState("");
    const docUrl = "https://mystpbucket.s3.ap-south-1.amazonaws.com/";
    const [fileInputs, setFileInputs] = useState({
        banner: null,
    });

    const payloadObj = {
        Title: "",
        Description: "",
        Url: "",
        // banner: null,
        User_role: "admin",
    };

    const [payload, setPayload] = useState(
        idFromLocation
            ? {
                ...bannerById,
                Image: bannerById?.Image || [],
                banner: bannerById?.banner || [],
            }
            : payloadObj
    );

    const handleChange = (e) => {
        const { name, value } = e.target;
        const filesValue = e.target.files?.[0];
        if (name === "banner") {
            const temp = URL.createObjectURL(filesValue);
            if (temp) {
                setImagePreview(temp);
            }
            setFileInputs({ ...fileInputs, [name]: filesValue });
        } else {
            setPayload({ ...payload, [name]: value });
        }
    };

    const handleResponseAddBanner = (success = false, res) => {
        if (success) {
            enqueueSnackbar(`Banner added successfully.`, { variant: "success" });
            navigate("/banners");
        } else {
            enqueueSnackbar(
                `${res?.data?.message || "Something went wrong, Please check all fields."
                }`,
                { variant: "error" }
            );
        }
    };

    const handleResponseUpdateBanner = (success = false, res) => {
        if (success) {
            enqueueSnackbar(`Banner updated successfully.`, { variant: "success" });
            navigate("/banners");
        } else {
            enqueueSnackbar(
                `${res?.data?.error || "Something went wrong, Please check all fields."
                }`,
                { variant: "error" }
            );
        }
    };

    const handleClick = () => {
        const formData = new FormData();

        Object.entries(payload).forEach(([key, value]) => {
            if (key === "Image") {
                var stringifiedImg = JSON.stringify(value);
                formData.append(key, stringifiedImg);
            } else {
                formData.append(key, value);
            }
        });

        Object.entries(fileInputs).forEach(([key, value]) => {
            if (value) {
                formData.append(key, value);
            }
        });

        if (type === "add") {
            dispatch(AddBanner(formData, handleResponseAddBanner));
        } else {
            dispatch(
                UpdateBanner(idFromLocation, formData, handleResponseUpdateBanner)
            );
        }
    };

    useEffect(() => {
        if (idFromLocation) {
            dispatch(GetBannerById(idFromLocation));
        }
    }, [idFromLocation, dispatch]);

    useEffect(() => {
        if (idFromLocation && bannerById) {
            const updatedPayload = {
                ...bannerById,
            };
            setPayload(updatedPayload);
        }
    }, [bannerById, idFromLocation]);

    useEffect(() => {
        if (type === "add") {
            setPayload(payloadObj);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    console.log("payload", payload);
    console.log("fileInputs", fileInputs);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={3}
                p={2}
                mb={1}
                textAlign="center"
            >
                <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                    Banner Management
                </MDTypography>
            </MDBox>
            <MDBox
                mx={2}
                mt={3}
                py={3}
                p={4}
                mb={2}
                borderRadius="lg"
                variant="gradient"
                coloredShadow="info"
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    gap={3}
                    pb={2}
                >
                    <MDInput
                        // required
                        type="text"
                        label={`Title (${payload?.Title?.length || 0}/35 characters)`}
                        name="Title"
                        value={payload?.Title || ""}
                        margin="dense"
                        inputProps={{ maxLength: 35 }}
                        sx={widthStyle}
                        onChange={handleChange}
                    />
                    <MDInput
                        required
                        type="text"
                        label="Url"
                        name="Url"
                        value={payload?.Url || ""}
                        margin="dense"
                        sx={widthStyle}
                        onChange={handleChange}
                    />
                    <TextField
                        // required
                        label={`Description (${payload?.Title?.length || 0}/90 characters)`}
                        name="Description"
                        value={payload?.Description || ""}
                        multiline
                        rows={4} // Adjust the number of rows as needed
                        inputProps={{ maxLength: 90 }}
                        variant="outlined"
                        fullWidth
                        onChange={handleChange}
                    />
                    <Grid
                        container
                        direction="column"
                        justifyContent="space-between"
                        gap={3}
                    >
                        <Grid item xs={4}>
                            <MDTypography variant="h6" fontWeightLighter>
                                {type === "edit" ? "Update banner image" : "Add banner image"}
                                <span style={{ fontSize: "14px", fontWeight: "400" }}>
                                    {" "}
                                    (Preferred dimensions are 1800 x 1200)
                                </span>
                            </MDTypography>
                            <MDInput
                                type="file"
                                name="banner"
                                margin="dense"
                                fullWidth
                                onChange={handleChange}
                            />
                            {imagePreview ? (
                                <img
                                    src={`${imagePreview}`}
                                    alt="Event images"
                                    style={{
                                        width: "50%",
                                        aspectRatio: "3/2",
                                        objectFit: "contain",
                                    }}
                                />
                            ) : (
                                <MDTypography
                                    variant="h6"
                                    sx={{ fontSize: "14px", fontWeight: "400" }}
                                >
                                    No latest images selected yet.
                                </MDTypography>
                            )}
                            {type === "edit" && payload?.Image ? (
                                <ImageList
                                    sx={{
                                        width: "100%",
                                        height: "auto",
                                        display: "flex",
                                        flexWrap: "wrap",
                                        marginTop: "2rem",
                                        padding: "5px",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        "@media (max-width: 961px)": {
                                            justifyContent: "center",
                                        },
                                    }}
                                    rowHeight={164}
                                >
                                    {type === "edit" && (
                                        <ImageListItem style={{ width: "200px" }}>
                                            <img
                                                src={`${docUrl}${payload?.Image}`}
                                                alt={"Event Images"}
                                                style={{
                                                    width: "100%",
                                                    aspectRatio: "3/2",
                                                    objectFit: "contain",
                                                }}
                                            />
                                        </ImageListItem>
                                    )}
                                </ImageList>
                            ) : null}
                        </Grid>
                    </Grid>
                </Grid>
            </MDBox>
            <Grid container direction="row" justifyContent="flex-end" p={2} pb={0.5}>
                <MDButton variant="gradient" color="info" onClick={handleClick}>
                    {type === "add" ? "Add Banner" : "Update banner"}
                </MDButton>
            </Grid>
        </DashboardLayout>
    );
};

export default BannerForm;
